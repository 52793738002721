/* eslint-disable */
const test = "https://api.heybringo.com";

//const env = prod;
const env = test;

export const BASE_URL = `${env}/`;

/* eslint-disable */

