import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";
// @mui
import { alpha, styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Stack,
  Card,
  Avatar,
  CardHeader,
  Typography,
} from "@mui/material";
// components
import Iconify from "components/Iconify";
import Label from "components/Label";
import { ARAC_TIPLERI } from "helper";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));

// ----------------------------------------------------------------------

export default function VehicleTypes({ data }) {
  const theme = useTheme();
  const icons = [
    {
      icon: "images/customerDashboard/frigo.svg",
      color: "warning",
    },
    {
      icon: "images/customerDashboard/motorcycle.svg",
      color: "success",
    },
    {
      icon: "images/customerDashboard/panelvan.svg",
      color: "info",
    },
    {
      icon: "images/customerDashboard/standart.svg",
      color: "error",
    },
  ];

  const newData = data.map((item, index) => {
    return {
      name: item.name,
      icon: icons[index]?.icon,
      color: icons[index]?.color,
      count: item.count,
    };
  });

  if (newData.length === 0) return null;

  return (
    <Card>
      <CardHeader title="Araç Tipleri" />
      <Stack spacing={3} sx={{ p: 3 }}>
        {newData.map((author, index) => {
          const color = ARAC_TIPLERI[author.name]?.color || "primary";
          const icon = ARAC_TIPLERI[author.name]?.icon || null;
          const color1 = theme.palette[color].main;
          const bgColor = alpha(theme.palette[color].main, 0.08);
          return (
            <Stack
              key={`vehicleType${index}`}
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <IconWrapperStyle
                sx={{ color: color1, backgroundColor: bgColor }}
              >
                <Box
                  component="img"
                  src={icon}
                  sx={{ width: 20, height: 20 }}
                />
              </IconWrapperStyle>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">{author.name}</Typography>
              </Box>
              <Label
                variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                color={author.color}
              >
                {author.count} Araç
              </Label>
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
}
