import React from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { CircularProgress, Stack, Typography, useTheme } from "@mui/material";
//
import { Cursors } from "..";
import Search from "./Search";
import Actions from "./Actions";
import Controls from "./Controls";
import DistanceStatus from "./DistanceStatus";
import SelectsMarkers from "./SelectsMarkers";
import CustomMarkerPoint from "./CustomMarkerPoint";
import CustomMarkerRouteOrder from "./CustomMarkerRouteOrder";
import { usePlanner } from "../../../contexts/PlannerProvider";
import {
  enable,
  drawFreeHand,
  drawPolygonHand,
} from "../../../utils/mapHandles";
import MapLegends from "./Legends";
import Warehouses from "./Warehouses";
import OrderDetailPoint from "./OrderDetailPoint";
import SelectRouteOrdersBox from "./SelectRouteOrdersBox";
import MapLegendsBranches from "./Branches";
import { MAP_API_KEY } from "config";

const DraggableCursor = {
  centered: "grab",
  allSelect: "grab",
  lasso: "url(/images/planner-new/map/3.svg), auto",
  measure: "url(/images/planner-new/map/5.svg), auto",
  freeHand: "url(/images/planner-new/map/2.svg), auto",
  ordersCompleted: "grab",
  ordersNotCompleted: "grab",
};

type Props = {
  cursor: Cursors;
  setCursor(cursor: Cursors): void;
};

export default function DispatcherNewMap(props: Props) {
  const { palette } = useTheme();
  const { cursor, setCursor } = props;
  const {
    points,
    pointsSame,
    selectPoints,
    selectRoutes,
    mapLegends,
    mapLegendsBranches,
    mapLegendsWarehouses,
    clickPoint,
    selectPointsOrders,
    handleSelectPoints,
    selectMapRouteOrders,
    setSelectMapRouteOrders,
    handleSelectPointsLoading,
    routeOrdersFilters,
  } = usePlanner();
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: MAP_API_KEY,
  });
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [measureMarkers, setMeasureMarkers] = React.useState<
    google.maps.Marker[]
  >([]);

  const selectRoutesOrders = React.useMemo(
    () =>
      selectRoutes
        .flatMap((s) => {
          return s.orders.map((o) => {
            return {
              ...o,
              routeId: s.route.route_id,
              route_color: s.route.route_color,
              routeCoords: s.route.route_coordinates
                ? JSON.parse(s.route.route_coordinates)
                : [],
            };
          });
        })
        .filter((o) => {
          if (routeOrdersFilters.completed) {
            return ["DELIVERED", "NOT_DELIVERED"].includes(o.delivery_status);
          }

          if (routeOrdersFilters.waiting) {
            return !["DELIVERED", "NOT_DELIVERED"].includes(o.delivery_status);
          }

          return o;
        }),
    [routeOrdersFilters.completed, routeOrdersFilters.waiting, selectRoutes]
  );

  const isSelectRoutes = selectRoutes.length > 0;

  const onLoad = React.useCallback(function callback(newMap: google.maps.Map) {
    setMap(newMap);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const centeredMap = React.useCallback(
    (boundList: Array<any>) => {
      if (boundList.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();

        for (const point of boundList) {
          const location = { lat: point.latitude, lng: point.longitude };
          bounds.extend(location);
        }

        map?.fitBounds(bounds);
      }
    },
    [map]
  );

  function addMarkerOnClick(e: google.maps.MapMouseEvent) {
    if (cursor !== "measure" || !map) {
      return;
    }

    if (measureMarkers.length === 2) {
      setMeasureMarkers((m) => {
        const oldMarkers = [...m];
        oldMarkers[measureMarkers.length - 1].setMap(null);
        oldMarkers.splice(measureMarkers.length - 1, 1);
        return oldMarkers;
      });
    }

    const marker = new google.maps.Marker({
      map,
      zIndex: 3,
      position: e.latLng,
    });

    setMeasureMarkers((m) => [...m, marker]);
  }

  function deleteMeasureMarkers() {
    measureMarkers.forEach((m) => m.setMap(null));
    setMeasureMarkers([]);
    setCursor("centered");
  }

  React.useEffect(() => {
    if (!map) {
      return;
    }

    if (mapLegends.rotalar && isSelectRoutes) {
      centeredMap(
        selectRoutesOrders.map((o) => ({
          latitude: o.address.latitude,
          longitude: o.address.longitude,
        }))
      );
    } else if (mapLegends.siparisler && selectPointsOrders.length > 0) {
      const coordinates = selectPointsOrders.map((o) => {
        return {
          latitude: o.address.latitude,
          longitude: o.address.longitude,
        };
      });
      centeredMap(coordinates);
    } else if (mapLegends.subeler) {
      centeredMap(
        mapLegendsBranches.map((branch) => ({
          latitude: branch.address.latitude,
          longitude: branch.address.longitude,
        }))
      );
    } else if (mapLegends.depolar) {
      centeredMap(
        mapLegendsWarehouses.map((warehouse) => ({
          latitude: warehouse.latitude,
          longitude: warehouse.longitude,
        }))
      );
    } else {
      centeredMap(points);
    }
  }, [
    map,
    points,
    mapLegends,
    isSelectRoutes,
    selectPointsOrders,
    selectRoutesOrders,
  ]);

  React.useEffect(() => {
    if (!map) {
      return;
    }

    const clicked = google.maps.event.addListener(map, "click", (e: any) => {
      const visibleOrders = mapLegends.siparisler;
      const newPoints = visibleOrders
        ? points
        : selectRoutesOrders.map((o) => ({
            ...o,
            latitude: o.address.latitude,
            longitude: o.address.longitude,
          }));

      const handleMap = visibleOrders
        ? handleSelectPoints
        : setSelectMapRouteOrders;

      if (cursor === "centered") {
        enable(map);
      } else if (cursor === "freeHand") {
        drawFreeHand({
          map,
          points: newPoints,
          setCursor,
          handleSelectPoints: handleMap,
        });
      } else if (cursor === "lasso") {
        drawPolygonHand({
          map,
          points: newPoints,
          setCursor,
          handleSelectPoints: handleMap,
        });
      }
    });

    return () => {
      clicked.remove();
    };
  }, [map, points, cursor, mapLegends.siparisler, selectRoutesOrders]);

  if (!isLoaded) {
    return (
      <Stack width={1} height={1} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <Actions
        cursor={cursor}
        setCursor={setCursor}
        centeredMap={() => centeredMap(points)}
        allSelectsPoint={() => handleSelectPoints(points)}
      />
      <Search />
      <Controls map={map} />
      <GoogleMap
        zoom={7}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={addMarkerOnClick}
        mapContainerStyle={{
          inset: 0,
          position: "absolute",
        }}
        options={{
          minZoom: 3,
          zoomControl: false,
          mapTypeControl: false,
          clickableIcons: false,
          streetViewControl: false,
          fullscreenControl: false,
          // mapTypeId: google.maps.MapTypeId.HYBRID,
          draggableCursor: DraggableCursor[cursor],
        }}
      >
        <>
          {mapLegends.rotalar && (
            <>
              {selectRoutesOrders.map((order, index) => {
                return (
                  <CustomMarkerRouteOrder
                    order={order}
                    key={`order-point-${index}`}
                  />
                );
              })}
            </>
          )}
          {mapLegends.siparisler && (
            <>
              {points.map((point, index) => {
                const selected = Boolean(
                  selectPoints.find((p) => p.order_id === point.order_id)
                );
                const samePoint = !Boolean(
                  pointsSame.find((i) => i.order_id === point.order_id)
                );

                return (
                  <CustomMarkerPoint
                    point={point}
                    isSame={samePoint}
                    selected={selected}
                    key={`point-${index}`}
                  />
                );
              })}
            </>
          )}
          {/* // Legends */}
          <Warehouses />
          <MapLegendsBranches />
          {/* // Legends */}
        </>
        {measureMarkers.length === 2 && (
          <DistanceStatus
            setCursor={setCursor}
            addMarkers={measureMarkers}
            deleteMarkerOnClick={deleteMeasureMarkers}
          />
        )}
        {selectPoints.length > 0 && (
          <SelectsMarkers
            selectPoints={selectPoints}
            onClose={() => setCursor("centered")}
          />
        )}
      </GoogleMap>
      {clickPoint && (
        <OrderDetailPoint
          clickPoint={clickPoint}
          isSelectPoints={selectPoints.length > 0}
        />
      )}
      <MapLegends />
      {handleSelectPointsLoading && (
        <Stack
          zIndex={3}
          spacing={1}
          alignItems="center"
          justifyContent="center"
          sx={{
            inset: 0,
            width: 1,
            height: 1,
            position: "absolute",
            bgcolor: `${palette.grey[900]}90`,
          }}
        >
          <Typography variant="subtitle2" color="grey.0">
            Seçilen Siparişler Getiriliyor...
          </Typography>
          <CircularProgress size={30} />
        </Stack>
      )}
      {selectMapRouteOrders.length > 0 && !mapLegends.siparisler && (
        <SelectRouteOrdersBox />
      )}
    </>
  );
}
